export default {
	root: ({ props, context, parent, instance }) => ({
		class: ['datatable-root']
	}),
	tablecontainer: {
		class: ['datatable-tablecontainer'],
	},
	thead: {
		class: ['datatable-thead'],
	},
	headerrow: {
		class: ['datatable-header-row'],
	},
	bodyrow: {
		class: ['datatable-body-row'],
	},
};