export default defineNuxtPlugin((nuxtApp) => {
    // figure out the base API url
    const runtimeConfig = useRuntimeConfig();

    const baseUrl = runtimeConfig.public.pb.apiDomain;
    const v3baseUrl = runtimeConfig.public.pb.apiDomainV3;

    function create(url: string, values?: any, parameters?: any): string {
        if (!values) {
            return url;
        }
        for (var key in values) {
            if (!values.hasOwnProperty(key)) {
                continue;
            }

            url = url.replace(`{${key}}`, values[key]);
        }
        return url;
    }

    return {
        provide: {
            api: {
                v3: {
                    me: {
                        updateMe: () => `${v3baseUrl}/me`,
                        logout: () => `${v3baseUrl}/me/logout`,
                        akas: {
                            createAka: () => `${v3baseUrl}/me/akas`,
                            deleteAka: (akaId: number) => `${v3baseUrl}/me/akas/${akaId}`,
                        },
                        emails: {
                            sendVerification: () => `${v3baseUrl}/me/emails/verify`,
                            createEmail: () => `${v3baseUrl}/me/emails`,
                            deleteEmail: (emailId: number) => `${v3baseUrl}/me/emails/${emailId}`,
                            updateEmail: (emailId: number) => `${v3baseUrl}/me/emails/${emailId}`,
                        },
                        address: {
                            createAddress: () => `${v3baseUrl}/me/addresses`,
                            deleteAddress: (addressId: number) => `${v3baseUrl}/me/addresses/${addressId}`,
                            updateAddress: (addressId: number) => `${v3baseUrl}/me/addresses/${addressId}`,
                        },
                        phones: {
                            createPhone: () => `${v3baseUrl}/me/phones`,
                            deletePhone: (phoneId: number) => `${v3baseUrl}/me/phones/${phoneId}`,
                            sendVerification: () => `${v3baseUrl}/me/phones/verify`,
                            updatePhone: (phoneId: number) => `${v3baseUrl}/me/phones/${phoneId}`,
                        },

                        poa: {
                            sign: () => `${v3baseUrl}/me/lpoa`,
                            retract: () => `${v3baseUrl}/me/lpoa`,
                        },

                        mfa: {
                            get: () => `${v3baseUrl}/me/mfa`,
                            enable: () => `${v3baseUrl}/me/mfa`,
                            disable: () => `${v3baseUrl}/me/mfa`,
                        },

                        avatar: {
                            upload: () => `${v3baseUrl}/me/avatar`,
                            delete: () => `${v3baseUrl}/me/avatar`,
                        },

                        exposures: {
                            suppressions: {
                                get_suppressions: () => `${v3baseUrl}/me/exposures/suppressions`,
                                update_suppressions: () => `${v3baseUrl}/me/exposures/suppressions`,
                                remove_suppression: (suppressionId: number) =>
                                    `${v3baseUrl}/me/exposures/suppressions/${suppressionId}`,
                            },
                        },
                    },
                    people: {
                        //expsosures
                        person: (person_id: number) => `${v3baseUrl}/people/${person_id}`,
                        activity: (person_id: number) => `${v3baseUrl}/people/${person_id}/exposures/activity`,
                        pipeline: (person_id: number) => `${v3baseUrl}/people/${person_id}/exposures/statistics/pipeline`,
                        statistics: (person_id: number) => `${v3baseUrl}/people/${person_id}/exposures/statistics`,
                        exposures: (person_id: number) => `${v3baseUrl}/people/${person_id}/exposures/data-exposed`,
                        list_exposures: (person_id: number) => `${v3baseUrl}/people/${person_id}/exposures`,

                        exposure_radar: (person_id: number) => ` ${v3baseUrl}/people/${person_id}/exposures/radar`,
                        companies: {
                            get_companies: (person_id: number) => `${v3baseUrl}/people/${person_id}/companies?per_page=50`,
                            get_company_relationship: (person_id: number, company_id: number) =>
                                `${v3baseUrl}/people/${person_id}/companies/${company_id}`,
                            trust_company: (person_id: number, company_id: number) =>
                                `${v3baseUrl}/people/${person_id}/companies/${company_id}/trust`,
                            untrust_company: (person_id: number, company_id: number) =>
                                `${v3baseUrl}/people/${person_id}/companies/${company_id}/untrust`,
                            deletion_guide: (company_id: number): string => `${v3baseUrl}/companies/${company_id}/guide`,
                        },

                        contacts: {
                            get_contacts: (person_id: number) =>
                                `${v3baseUrl}/people/${person_id}/contacts?per_page=10000&blocked=false`,
                            get_trust_requests: (person_id: number) => `${v3baseUrl}/people/${person_id}/contacts/requests`,
                            create_contact: (person_id: number) => `${v3baseUrl}/people/${person_id}/contacts`,
                        },

                        //pb university
                        university: {
                            get_statistics: (person_id: number) => `${v3baseUrl}/people/${person_id}/university/statistics`,
                            get_module_info: (person_id: number, module: string) =>
                                `${v3baseUrl}/people/${person_id}/university/modules/${module}`,
                        },
                    },
                    apps: {
                        private_search: {
                            serach: (query: string, type: string, page: number) =>
                                `${v3baseUrl}/apps/search?type=${type}&q=${query}&page=${page}`,
                            suggest: (query: string) => `${v3baseUrl}/apps/search/suggest?q=${query}`,
                            image_proxy: (image_link: string) => `${v3baseUrl}/apps/search/image?image=${image_link}`,
                        },

                        buzz: {
                            get_activity: (per_page: number) =>
                                `${v3baseUrl}/apps/buzz/activity?start=1&per_page=${per_page ? per_page : 10000}`,
                            log_activity: () => `${v3baseUrl}/apps/buzz/activity`,
                        },
                    },
                    public: {
                        compmanies: {
                            search: (query: string, per_page: number) =>
                                `${v3baseUrl}/public/companies?per_page=${per_page}&search=${query}`,
                        },
                    },
                },

                search_proxy: `${baseUrl}/image-proxy/`,
                address: {
                    get_countries: `${baseUrl}/countries/`,
                    get_country: (code: string) => `${baseUrl}/countries/${code}`,
                },
                public: {
                    companies: {
                        get: (slug: string) => `${baseUrl}/companies/methods/public/${slug}`,
                        trusted: `${baseUrl}/companies/methods/recently_trusted`,
                        updated: `${baseUrl}/companies/methods/recently_updated`,
                    },
                },
                admin: {
                    people: {
                        add_email: (person: number) => `${baseUrl}/admin/people/${person}/add_email`,
                        add_phone: (person: number) => `${baseUrl}/admin/people/${person}/add_phone`,
                    },
                    stats: {
                        root: `${baseUrl}/stats`,
                        viewed_content: `${baseUrl}/admin/get_viewed_content`,
                        email: (startDate: string, endDate: string) => `${baseUrl}/stats/emails_sent/${startDate}/${endDate}`,
                        carpenters: {
                            task_history: (type: string) => `${baseUrl}/stats/carpenters/task_history/${type}`,
                            task_history_dates: (type: string, startDate: string, endDate: string) =>
                                `${baseUrl}/stats/carpenters/task_history/${type}/${startDate}/${endDate}`,
                            people: (type: string) => `${baseUrl}/stats/carpenters/people/${type}`,
                            velocity_all: (startDate: string, endDate: string, interval: string) =>
                                `${baseUrl}/stats/carpenters/tasks/velocity/${startDate}/${endDate}/${interval}`,
                            heatmap_all: (startDate: string, endDate: string) =>
                                `${baseUrl}/stats/carpenters/tasks/heatmap/${startDate}/${endDate}`,
                            heatmap_carpenter: (carpenter: string, startDate: string, endDate: string) =>
                                `${baseUrl}/stats/carpenters/tasks/heatmap/${carpenter}/${startDate}/${endDate}`,
                            stats: (startDate: string, endDate: string) =>
                                `${baseUrl}/stats/carpenters/tasks_stats/${startDate}/${endDate}`,
                            accuracy: `${baseUrl}/stats/carpenters/accuracy`,
                            quota: `${baseUrl}/stats/carpenter/quota`,
                            quota_all: `${baseUrl}/stats/carpenters/quota`,
                        },
                    },
                    partner_analytics: {
                        root: `${baseUrl}/partner_analytics/stats`,
                        getStatsRevenue: (startDate: string, endDate: string) =>
                            `${baseUrl}/partner_analytics/get_stats_revenue/${startDate}/${endDate}`,
                        getStatsConsumer: (startDate: string, endDate: string) =>
                            `${baseUrl}/partner_analytics/get_stats_consumer/${startDate}/${endDate}`,
                        getStatsBusiness: (startDate: string, endDate: string) =>
                            `${baseUrl}/partner_analytics/get_stats_business/${startDate}/${endDate}`,
                        getRevenueChartData: (startDate: string, endDate: string) =>
                            `${baseUrl}/partner_analytics/get_revenue_chart_data/${startDate}/${endDate}`,
                        getRegistrationChartData: (startDate: string, endDate: string) =>
                            `${baseUrl}/partner_analytics/get_registration_chart_data/${startDate}/${endDate}`,
                        getLicenseChartData: (startDate: string, endDate: string) =>
                            `${baseUrl}/partner_analytics/get_license_chart_data/${startDate}/${endDate}`,
                    },
                    employee_stats: `${baseUrl}/stats/member_tasks`,
                    employee_commission: `${baseUrl}/stats/employee_commission`,
                    person_history: (person_id: string) => `${baseUrl}/people/methods/person_history/${person_id}/`,
                    person_history_download: (person_id: number) =>
                        `${baseUrl}/people/methods/person_history_download/${person_id}/`,
                    employee_history: `${baseUrl}/stats/employee_history`,
                    tasks: {
                        get_next_task: `${baseUrl}/people/methods/get_next_task`,
                        confirm_unable: (person_id: number, slug: string) =>
                            `${baseUrl}/people/methods/confirm_unable/${person_id}/${slug}`,
                    },
                    businesses: {
                        get_features: (business_id: string) => `${baseUrl}/admin/businesses/features/${business_id}`,
                    },
                },
                billing: {
                    chart: `${baseUrl}/billing/chart-data`,
                    methods: {
                        get: `${baseUrl}/billing/payment_methods`,
                        setup_intent: `${baseUrl}/billing/methods/setup-intent`,
                        set_default: `${baseUrl}/billing/methods/set-default`,
                        add: `${baseUrl}/billing/methods/add`,
                        remove: `${baseUrl}/billing/methods/remove`,
                        promo_code: `${baseUrl}/billing/methods/coupon`,
                        update_billing_cycle: `${baseUrl}/billing/update_billing_cycle`,
                        update_payment_method: `${baseUrl}/billing/update_payment_method`,
                        subscribe: `${baseUrl}/billing/subscribe`,
                        unsubscribe: `${baseUrl}/billing/unsubscribe`,
                        resubscribe: `${baseUrl}/billing/resubscribe`,
                        add_pro_to_member: (person_id: number) => `${baseUrl}/billing/methods/activate_pro/${person_id}`,
                    },
                    invoices: {
                        root: (user_id: string) => `${baseUrl}/billing/${user_id}/invoices`,
                        single: (user_id: string, id: string) => `${baseUrl}/billing/${user_id}/invoices/${id}`,
                    },
                },
                auth: {
                    root: `${baseUrl}/auth`,
                    me: `${baseUrl}/auth/me`,
                    extension: `${baseUrl}/auth/extension`,
                    logout: `${baseUrl}/auth/logout`,
                    reset_password: {
                        root: `${baseUrl}/auth/reset_password`,
                        with_token: (token: string) => `${baseUrl}/auth/reset_password/${token}`,
                        with_auth: `${baseUrl}/auth/reset_password/with_auth`,
                    },
                    verify_email: {
                        root: `${baseUrl}/auth/verify_email`,
                        with_token: (token: string) => `${baseUrl}/auth/verify_email/${token}`,
                        additional: {
                            root: `${baseUrl}/auth/verify_email/additional`,
                            with_token: (token: string) => `${baseUrl}/auth/verify_email/additional/${token}`,
                        },
                    },
                    signup: `${baseUrl}/auth/signup`,
                    email_signup: `${baseUrl}/auth/email_signup`,
                    universal: {
                        check_email: `${baseUrl}/auth/universal/check_email`,
                        login: `${baseUrl}/auth/universal/login`,
                        request_pin: `${baseUrl}/auth/universal/request_pin`,
                        update_account: `${baseUrl}/auth/universal/update_account`,
                        check_company: (email: string, business: string) =>
                            `${baseUrl}/auth/universal/check_company/${email}/${business}`,
                        create_business: `${baseUrl}/auth/universal/create_business`,
                    },
                },
                users: {
                    root: `${baseUrl}/users`,
                    user: (user_id: string) => `${baseUrl}/users/${user_id}`,
                    concierge: `${baseUrl}/users/concierge`,
                },
                family_signups: {
                    root: `${baseUrl}/family_signups`,
                },
                person_settings: {
                    root: `${baseUrl}/person_settings`,
                    person_setting: (person_setting_id: string) => `${baseUrl}/person_settings/${person_setting_id}`,
                },
                notifications: {
                    root: `${baseUrl}/notifications`,
                    user_setting: (notification_id: string) => `${baseUrl}/notifications/${notification_id}`,
                },
                logo_preview: {
                    root: `${baseUrl}/companies/logo`,
                },
                phone_numbers: {
                    root: `${baseUrl}/user_phones`,
                    user_phone: (user_phone_number_id: string) => `${baseUrl}/user_phones/${user_phone_number_id}`,
                },
                conference: {
                    root: `${baseUrl}/sales`,
                },
                employees: {
                    root: `${baseUrl}/employees`,
                    employee: (employee_id: string) => `${baseUrl}/employees/${employee_id}`,
                    set_password: `${baseUrl}/employees/set_password`,
                    check_token: `${baseUrl}/employees/check_token`,
                    check_password: `${baseUrl}/employees/check`,
                    methods: {
                        change_license: (employee_id: string) => `${baseUrl}/employees/methods/change_license/${employee_id}`,
                        change_access: (employee_id: string) => `${baseUrl}/employees/methods/change_access/${employee_id}`,
                        remove_employee: (employee_id: string) => `${baseUrl}/employees/methods/remove_employee/${employee_id}`,
                        delete_employee: (employee_id: string) => `${baseUrl}/employees/methods/delete_employee/${employee_id}`,
                        update_employee: (employee_id: string) => `${baseUrl}/employees/methods/update_employee/${employee_id}`,
                        resend_invitation: (employee_id: string) =>
                            `${baseUrl}/employees/methods/resend_invitation/${employee_id}`,
                    },
                },
                departments: {
                    root: `${baseUrl}/departments`,
                    department: (department_id: string) => `${baseUrl}/departments/${department_id}`,
                },
                honey_feed: {
                    email: `${baseUrl}/honey_feed`,
                    phone: `${baseUrl}/honey_feed`,
                    address: `${baseUrl}/honey_feed`,
                },
                affiliate_invoices: {
                    root: `${baseUrl}/affiliate_invoices`,
                    affiliate_invoice: (affiliate_invoice_id: string) => `${baseUrl}/affiliate_invoices/${affiliate_invoice_id}`,
                },
                business_tokens: {
                    root: `${baseUrl}/business_tokens`,
                    business_token: (business_token_id: string) => `${baseUrl}/business_tokens/${business_token_id}`,
                },
                companies: {
                    root: `${baseUrl}/companies`,
                    company: (slug: string) => `${baseUrl}/companies/${slug}`,
                    history: `${baseUrl}/companies/methods/history`,
                    popular: `${baseUrl}/companies/methods/popular`,
                    url: (website_url: string) => `${baseUrl}/companies/url/${website_url}`,
                    trust_search: `${baseUrl}/companies/trust_search`,
                    methods: {
                        trust_score_search: `${baseUrl}/companies/methods/trust_score_search/`,
                        submit_request: `${baseUrl}/companies/methods/request`,
                        get_data_types: `${baseUrl}/companies/methods/get_data_types`,
                    },
                },
                extension: {
                    root: `${baseUrl}/extension`,
                },
                domains: {
                    root: `${baseUrl}/domains`,
                },
                get_company_list: {
                    root: `${baseUrl}/company_list`,
                },
                privacy_exposures: {
                    root: (person: string) => `${baseUrl}/privacy-exposures/${person}`,
                    graph: (person: string) => `${baseUrl}/privacy-exposures/${person}/graph`,
                    list: (person: string) => `${baseUrl}/privacy-exposures/${person}/exposures`,
                    exposure_categories: (person: string) => `${baseUrl}/privacy-exposures/${person}/categories`,
                    exposure_category: (person: string, category: string) =>
                        `${baseUrl}/privacy-exposures/${person}/categories/${category}`,
                    mark_not_me: (person: string, company: string) =>
                        `${baseUrl}/privacy-exposures/${person}/exposures/${company}/not_me`,
                    close_data_breach: (person: string, person_breach: string) =>
                        `${baseUrl}/privacy-exposures/${person}/exposures/${person_breach}/close_breach`,
                    search_result: (person: string, person_search_result_id: string) =>
                        `${baseUrl}/privacy-exposures/${person}/search_result/${person_search_result_id}/request`,
                },
                company_person_tasks: {
                    root: `${baseUrl}/company_person_tasks`,
                    company_person_task: (company_person_task_id: string) =>
                        `${baseUrl}/company_person_tasks/${company_person_task_id}`,
                },
                privacy_frameworks: {
                    root: `${baseUrl}/privacy_frameworks`,
                    framework: (id: string) => `${baseUrl}/privacy_frameworks/${id}`,
                    requirements: `${baseUrl}/privacy_frameworks/requirements`,
                    requirement: (framework: string, requirement: string) =>
                        `${baseUrl}/privacy_frameworks/${framework}/requirements/${requirement}`,
                },
                company_person_relationships: {
                    root: `${baseUrl}/company_person_relationships`,
                    request: (company_person_relationship_id: string) =>
                        `${baseUrl}/company_person_relationships/${company_person_relationship_id}`,
                    sync: `${baseUrl}/companies/manual_popular`,
                },
                business_company_relationships: {
                    root: `${baseUrl}/business_company_relationships`,
                    request: (company_person_relationship_id: string) =>
                        `${baseUrl}/business_company_relationships/${company_person_relationship_id}`,
                },
                company_data_types_stored: {
                    root: `${baseUrl}/company_data_types_stored`,
                },
                breaches: {
                    root: `${baseUrl}/breaches`,
                    breach: (breach_id: string) => `${baseUrl}/breaches/${breach_id}`,
                    rename: `${baseUrl}/breaches/rename`,
                    signed_url: `${baseUrl}/vapor/signed-storage-url`,
                },
                licenses: {
                    root: `${baseUrl}/licenses`,
                },
                oauth: {
                    root: `${baseUrl}/oauth`,
                    token: `${baseUrl}/oauth/token`,
                },
                vulnerabilities: {
                    scan: `${baseUrl}/vulnerabilities/scan`,
                },
                person_breaches: {
                    scan: (person_id: number) => `${baseUrl}/person_breaches/scan_breaches/${person_id}`,
                    get: (person_id: number) => `${baseUrl}/person_breaches/get_breaches/${person_id}`,
                },
                email_scan_counts: {
                    root: `${baseUrl}/email_scan_counts`,
                },
                email_scans: {
                    root: `${baseUrl}/email_scans`,
                    authorize: `${baseUrl}/email_scan/authorize`,
                    authorize_imap: `${baseUrl}/email_scan/authorize_imap`,
                    revoke: `${baseUrl}/email_scan/revoke`,
                    opt_out: (email_scan_opt_out: string) => `${baseUrl}/email_scan/opt_out/${email_scan_opt_out}`,
                },
                families: {
                    root: `${baseUrl}/families`,
                    family: (family_id: string) => `${baseUrl}/families/${family_id}`,
                    methods: {
                        subscription_cancel: {
                            submit_feedback: `${baseUrl}/families/methods/subscription_cancel/submit_feedback`,
                            offers: `${baseUrl}/families/methods/subscription_cancel/offers`,
                            take_offer: (offer_id: string) =>
                                `${baseUrl}/families/methods/subscription_cancel/offers/${offer_id}`,
                            terminate: `${baseUrl}/families/methods/subscription_cancel/terminate`,
                            admin_terminate: (family_id: string) =>
                                `${baseUrl}/families/methods/subscription_cancel/admin_terminate/${family_id}`,
                        },
                    },
                },
                person_domains: {
                    root: `${baseUrl}/person_domains`,
                    get_all_person_domains: `${baseUrl}/get_all_person_domains`,
                    get_without_relation_person_domains: `${baseUrl}/get_without_relation_person_domains`,
                },
                search: {
                    root: `${baseUrl}/search`,
                },
                people: {
                    root: `${baseUrl}/people`,
                    list: `${baseUrl}/people/list`,
                    person: (person_id: number) => `${baseUrl}/people/${person_id}`,
                    methods: {
                        activate_pro: (person: string) => `${baseUrl}/people/${person}/activate_pro`,
                        cancel_pro: (person: string) => `${baseUrl}/people/${person}/cancel_pro`,
                        public_exposures: {
                            scan_status: `${baseUrl}/people/methods/public_exposures/scan_status`,
                        },
                        get_people_by_role: (role: string) => `${baseUrl}/people/methods/get_people_by_role/${role}`,
                        data: {
                            add_feature: (person_id: number) => `${baseUrl}/people/methods/data/add_feature/${person_id}`,
                            phone_numbers: {
                                add: (person_id: number) => `${baseUrl}/people/methods/data/phone_numbers/add/${person_id}`,
                                verify: (person_id: number) => `${baseUrl}/people/methods/data/phone_numbers/verify/${person_id}`,
                                update: (person_id: number) => `${baseUrl}/people/methods/data/phone_numbers/update/${person_id}`,
                                remove: (person_phone_number_id: string) =>
                                    `${baseUrl}/people/methods/data/phone_numbers/remove/${person_phone_number_id}`,
                            },
                            addresses: {
                                add: (person_id: number) => `${baseUrl}/people/methods/data/addresses/add/${person_id}`,
                                update: (person_id: number) => `${baseUrl}/people/methods/data/addresses/update/${person_id}`,
                                remove: (person_address_id: string) =>
                                    `${baseUrl}/people/methods/data/addresses/remove/${person_address_id}`,
                            },
                            emails: {
                                add: (person_id: number) => `${baseUrl}/people/methods/data/emails/add/${person_id}`,
                                remove: (person_email_id: string) =>
                                    `${baseUrl}/people/methods/data/emails/remove/${person_email_id}`,
                                verify: (person_id: number) => `${baseUrl}/people/methods/data/emails/verify/${person_id}`,
                                link: (person_id: number) => `${baseUrl}/people/methods/data/emails/link/${person_id}`,
                                verify_primary: (person_id: number) =>
                                    `${baseUrl}/people/methods/data/emails/verify_primary/${person_id}`,
                                update_primary: (person_id: number, email_id: string) =>
                                    `${baseUrl}/people/methods/data/emails/update_primary/${person_id}/${email_id}`,
                            },
                        },
                        todo: (person_id: number) => `${baseUrl}/people/methods/todo/${person_id}`,
                        found_member_data: (slug: string, person_id: number) =>
                            `${baseUrl}/people/methods/found_member_data/${slug}/${person_id}`,
                        complete_task: (slug: string, person_id: number) =>
                            `${baseUrl}/people/methods/complete_todo/${slug}/${person_id}`,
                        close_task: (slug: string, person_id: number) =>
                            `${baseUrl}/people/methods/close_task/${slug}/${person_id}`,
                        complete_task_no_data: (slug: string, person_id: number) =>
                            `${baseUrl}/people/methods/complete_todo_no_data/${slug}/${person_id}`,
                        make_priority: (slug: string, person_id: number) =>
                            `${baseUrl}/people/methods/make_priority/${slug}/${person_id}`,
                        update_features: (person_id: number) => `${baseUrl}/people/methods/update_features/${person_id}`,
                        poa: (person_id: number) => `${baseUrl}/people/methods/poa/${person_id}`,
                        email_poa: (person_id: number) => `${baseUrl}/people/methods/email_poa/${person_id}`,
                        sign_poa: (person_id: number) => `${baseUrl}/people/methods/sign_poa/${person_id}`,
                        search_results: `${baseUrl}/people/methods/get_search_results`,
                        run_serp: `${baseUrl}/people/methods/run_serp`,
                        get_geo: `${baseUrl}/people/methods/get_geo`,
                        people_search_sites: `${baseUrl}/people/methods/people_search_sites`,
                        gift_pro: (person_id: number) => `${baseUrl}/people/methods/gift_pro/${person_id}`,

                        wizard: {
                            step1_member_data_not_found: (slug: string, person_id: number) =>
                                `${baseUrl}/people/methods/wizard/step1_member_data_not_found/${slug}/${person_id}`,
                            step1_member_data_found: (slug: string, person_id: number) =>
                                `${baseUrl}/people/methods/wizard/step1_member_data_found/${slug}/${person_id}`,
                            step1_member_data_found_no_link: (slug: string, person_id: number) =>
                                `${baseUrl}/people/methods/wizard/step1_member_data_found_no_link/${slug}/${person_id}`,
                            step2_complete: (slug: string, person_id: number) =>
                                `${baseUrl}/people/methods/wizard/step2_complete/${slug}/${person_id}`,
                            step2_member_data_not_found: (slug: string, person_id: number) =>
                                `${baseUrl}/people/methods/wizard/step2_member_data_not_found/${slug}/${person_id}`,
                            step3_confirm_data_not_found: (slug: string, person_id: number) =>
                                `${baseUrl}/people/methods/wizard/step3_confirm_data_not_found/${slug}/${person_id}`,
                            step3_confirm_data_found: (slug: string, person_id: number) =>
                                `${baseUrl}/people/methods/wizard/step3_confirm_data_found/${slug}/${person_id}`,
                            skip_missing_required_data: (slug: string, person_id: number) =>
                                `${baseUrl}/people/methods/wizard/skip_missing_required_data/${slug}/${person_id}`,
                            skip_site_wont_load: (slug: string, person_id: number) =>
                                `${baseUrl}/people/methods/wizard/skip_site_wont_load/${slug}/${person_id}`,
                            skip_site_failure: (slug: string, person_id: number) =>
                                `${baseUrl}/people/methods/wizard/skip_site_failure/${slug}/${person_id}`,
                            confirm_search_submission: (person_id: number, person_search_result_id: string) =>
                                `${baseUrl}/people/methods/wizard/confirm_search_submission/${person_id}/${person_search_result_id}`,
                        },
                        exposures: {
                            all: `${baseUrl}/people/methods/exposures`,
                            get: (slug: string) => `${baseUrl}/people/methods/exposures/${slug}`,
                            reopen: (slug: string) => `${baseUrl}/people/methods/exposures/reopen/${slug}`,
                            history: (slug: string) => `${baseUrl}/people/methods/exposures/history/${slug}`,
                            admin: {
                                all: (person_id: number) => `${baseUrl}/people/methods/exposures/admin/${person_id}`,
                                get: (person_id: number, slug: string) =>
                                    `${baseUrl}/people/methods/exposures/admin/${person_id}/${slug}`,
                                close: (person_id: number, slug: string) =>
                                    `${baseUrl}/people/methods/exposures/admin/close/${person_id}/${slug}`,
                                reopen: (person_id: number, slug: string) =>
                                    `${baseUrl}/people/methods/exposures/admin/reopen/${person_id}/${slug}`,
                                history: (person_id: number, slug: string) =>
                                    `${baseUrl}/people/methods/exposures/admin/history/${person_id}/${slug}`,
                            },
                            company_faqs: {
                                root: `${baseUrl}/company_faqs/`,
                                company_faq: (person_id: number) => `${baseUrl}/company_faqs/${person_id}`,
                            },
                        },
                    },
                },
                v2: {
                    pss: {
                        emails: `${baseUrl}/admin/pss/emails`,
                        submit: `${baseUrl}/admin/pss/submit`,
                        spam: `${baseUrl}/admin/pss/spam`,
                        error: `${baseUrl}/admin/pss/error`,
                    },
                    public: {
                        company: {
                            check_token: (token: string, unsubscribe: string) =>
                                `${baseUrl}/public/company/check_token/${token}/${unsubscribe}`,
                            check_email: (token: string, email: string, unsubscribe: string) =>
                                `${baseUrl}/public/company/check_email/${token}/${email}/${unsubscribe}`,
                            update_relationship: `${baseUrl}/public/company/update_relationship`,
                        },
                    },
                    companies: {
                        list: `${baseUrl}/admin/companies`,
                        get_sales_person: `${baseUrl}/admin/get_sales_person`,
                        get_account_managers: `${baseUrl}/admin/get_account_managers`,
                    },

                    business: {
                        root: (slug: string) => `${baseUrl}/business/${slug}`,
                        sign_toc: (slug: string) => `${baseUrl}/business/${slug}/sign_toc`,
                        claim: `${baseUrl}/business/claim`,
                        children: (slug: string) => `${baseUrl}/business/${slug}/children`,
                        partner_companies: (slug: string) => `${baseUrl}/business/${slug}/partner_companies`,
                        history: (slug: string) => `${baseUrl}/business/${slug}/history`,
                        monthly_status: (slug: string) => `${baseUrl}/business/${slug}/email/monthly-status`,
                        partner: {
                            companies: {
                                list: (slug: string) => `${baseUrl}/business/${slug}/partner/companies/list`,
                            },
                            analytics: {
                                business: {
                                    stats: (slug: string) => `${baseUrl}/business/${slug}/partner/analytics/business/stats`,
                                    licenses: (slug: string) => `${baseUrl}/business/${slug}/partner/analytics/business/licenses`,
                                },
                                revenue: {
                                    stats: (slug: string) => `${baseUrl}/business/${slug}/partner/analytics/revenue/stats`,
                                    chart: (slug: string) => `${baseUrl}/business/${slug}/partner/analytics/revenue/chart`,
                                },
                            },
                        },
                        company_alerts: {
                            root: (slug: string) => `${baseUrl}/business/${slug}/company_alerts`,
                        },
                        settings: {
                            admin: (slug: string) => `${baseUrl}/business/${slug}/settings/admin`,
                            root: (slug: string) => `${baseUrl}/business/${slug}/settings`,
                            status: (slug: string) => `${baseUrl}/business/${slug}/status`,
                            update_logo: (slug: string) => `${baseUrl}/business/${slug}/settings/logo`,
                            authentication: (slug: string) => `${baseUrl}/business/${slug}/settings/authentication`,
                        },
                        phone_numbers: (slug: string) => `${baseUrl}/business/${slug}/phone_numbers`,
                        addPhoneNumber: (slug: string) => `${baseUrl}/business/${slug}/phone_numbers/addPhoneNumber`,
                        verifyPhoneNumber: (slug: string) => `${baseUrl}/business/${slug}/phone_numbers/verifyPhoneNumber`,
                        domains: (slug: string) => `${baseUrl}/business/${slug}/domains`,
                        verifyDomain: (slug: string, domain: string) => `${baseUrl}/business/${slug}/domains/${domain}/verify`,
                        departments: (slug: string) => `${baseUrl}/business/${slug}/departments`,
                        addresses: (slug: string) => `${baseUrl}/business/${slug}/addresses`,
                        get_privacy_risk_score: (slug: string) => `${baseUrl}/business/${slug}/get_privacy_risk_score`,
                        apps: {
                            root: (slug: string) => `${baseUrl}/business/${slug}/apps`,
                            activate: (slug: string) => `${baseUrl}/business/${slug}/apps/activate`,
                            user_consent_management: {
                                customer_search: (slug: string) =>
                                    `${baseUrl}/business/${slug}/apps/user_consent_management/customer_search`,
                            },
                            pra: {
                                get: (slug: string) => `${baseUrl}/business/${slug}/apps/pra`,
                                answer_question: (slug: string) => `${baseUrl}/business/${slug}/apps/pra/answer_question`,
                                get_vendor_pra_access_request: (slug: string) =>
                                    `${baseUrl}/business/${slug}/apps/pra/get_vendor_pra_access_request`,
                                get_vendor_pra_access_data: (slug: string) =>
                                    `${baseUrl}/business/${slug}/apps/pra/get_vendor_pra_access_data`,
                                revoke_pra_access: (slug: string, vendor: string) =>
                                    `${baseUrl}/business/${slug}/vendor/${vendor}/revoke_pra_access`,
                            },
                            privacy_frameworks: {
                                root: (slug: string) => `${baseUrl}/business/${slug}/privacy_frameworks`,
                                framework: (slug: string, id: string) => `${baseUrl}/business/${slug}/privacy_frameworks/${id}`,
                            },
                            role_management: {
                                root: (slug: string) => `${baseUrl}/business/${slug}/apps/role_management`,
                            },
                            consent_core: {
                                summary: (slug: string) => `${baseUrl}/business/${slug}/apps/consent_core/summary`,
                                list: (slug: string) => `${baseUrl}/business/${slug}/apps/consent_core/list`,
                                handle: (slug: string) => `${baseUrl}/business/${slug}/apps/consent_core/handle`,
                                export: (slug: string) => `${baseUrl}/business/${slug}/apps/consent_core/export`,
                            },
                        },
                        vcc: {
                            templates: {
                                root: (slug: string) => `${baseUrl}/business/${slug}/apps/vcc/templates`,
                            },
                            containers: {
                                root: (slug: string) => `${baseUrl}/business/${slug}/apps/vcc/containers`,
                                container: (slug: string, container_id: string) =>
                                    `${baseUrl}/business/${slug}/apps/vcc/containers/${container_id}`,
                                statistics: (slug: string, container_id: string, from: string, to: string) =>
                                    `${baseUrl}/business/${slug}/apps/vcc/containers/${container_id}/statistics/${from}/${to}`,
                                get_signups: (slug: string, container_id: string, from: string, to: string) =>
                                    `${baseUrl}/business/${slug}/apps/vcc/containers/${container_id}/get_signups/${from}/${to}`,
                                vendors: (slug: string, container_id: string) =>
                                    `${baseUrl}/business/${slug}/apps/vcc/containers/${container_id}/vendors`,
                                vendor: (slug: string, container_id: string, vendor_id: string) =>
                                    `${baseUrl}/business/${slug}/apps/vcc/containers/${container_id}/vendors/${vendor_id}`,
                                assign_category_to_vendor: (
                                    slug: string,
                                    container_id: string,
                                    vendor_id: string,
                                    category_id: string
                                ) =>
                                    `${baseUrl}/business/${slug}/apps/vcc/containers/${container_id}/vendors/${vendor_id}/assign_category_to_vendor/${category_id}`,
                                get_available_vendors: (slug: string, container_id: string) =>
                                    `${baseUrl}/business/${slug}/apps/vcc/containers/${container_id}/vendors/get_available_vendors`,
                                categories: (slug: string, container_id: string) =>
                                    `${baseUrl}/business/${slug}/apps/vcc/containers/${container_id}/categories`,
                                stats: (slug: string, container_id: string) =>
                                    `${baseUrl}/business/${slug}/apps/vcc/containers/${container_id}/stats`,
                                category: (slug: string, container_id: string, category_id: string) =>
                                    `${baseUrl}/business/${slug}/apps/vcc/containers/${container_id}/categories/${category_id}`,
                                signups: (slug: string) => `${baseUrl}/business/${slug}/apps/vcc/signup_stats`,
                            },
                        },
                        edpa: {
                            employees_coverage_rate: (slug: string) =>
                                `${baseUrl}/business/${slug}/apps/edpa/employees_coverage_rate`,
                            exposures_discovered: (slug: string) => `${baseUrl}/business/${slug}/apps/edpa/exposures_discovered`,
                            company_details: (slug: string) => `${baseUrl}/business/${slug}/apps/edpa/company_details`,
                            vendor_coverage_rate: (slug: string) => `${baseUrl}/business/${slug}/apps/edpa/vendor_coverage_rate`,
                            employee_privacy_audit_statistics: (slug: string) =>
                                `${baseUrl}/business/${slug}/apps/edpa/employee_privacy_audit_statistics`,
                            most_at_risk_employees: (slug: string) =>
                                `${baseUrl}/business/${slug}/apps/edpa/most_at_risk_employees`,
                            breach_statistics: (slug: string) => `${baseUrl}/business/${slug}/apps/edpa/breach_statistics`,
                            employee_visibility_and_exposure_overview: (slug: string) =>
                                `${baseUrl}/business/${slug}/apps/edpa/employee_visibility_and_exposure_overview`,
                            most_recent_breaches: (slug: string) => `${baseUrl}/business/${slug}/apps/edpa/most_recent_breaches`,
                            get_most_recent_vulnerabilities: (slug: string) =>
                                `${baseUrl}/business/${slug}/apps/edpa/get_most_recent_vulnerabilities`,
                            privacy_exposure_statistics: (slug: string) =>
                                `${baseUrl}/business/${slug}/apps/edpa/privacy_exposure_statistics`,
                            employee_privacy_highlights: (slug: string) =>
                                `${baseUrl}/business/${slug}/apps/edpa/employee_privacy_highlights`,
                            account_manager: (slug: string) => `${baseUrl}/business/${slug}/apps/edpa/account_manager`,
                            edpa_history: (slug: string) => `${baseUrl}/business/${slug}/apps/edpa/edpa_history`,
                        },
                        pbu: {
                            training: {
                                get_statistics: (slug: string) => `${baseUrl}/business/${slug}/apps/pbu/statistics`,
                                get_module: (slug: string, module: string) =>
                                    `${baseUrl}/business/${slug}/apps/pbu/module/${module}`,
                                video_track: (slug: string, module: string, lesson: string) =>
                                    `${baseUrl}/business/${slug}/apps/pbu/module/${module}/lesson/${lesson}`,
                                change_module: (slug: string, module: string) =>
                                    `${baseUrl}/business/${slug}/apps/pbu/module/${module}`,
                                get_certificate: (slug: string, module: string) =>
                                    `${baseUrl}/business/${slug}/apps/pbu/certificate/${module}`,
                                get_next_lesson: (slug: string, module: string, lesson: string) =>
                                    `${baseUrl}/business/${slug}/apps/pbu/module/${module}/next_lesson/${lesson}`,
                            },
                            manage: {
                                get_statistics: (slug: string) => `${baseUrl}/business/${slug}/apps/manage_pbu/statistics`,
                                get_employees: (slug: string) => `${baseUrl}/business/${slug}/apps/manage_pbu/employees`,
                                get_employee_history: (slug: string) =>
                                    `${baseUrl}/business/${slug}/apps/manage_pbu/employee_history`,
                                get_settings: (slug: string) => `${baseUrl}/business/${slug}/apps/manage_pbu/settings`,
                                get_status_export: (slug: string) => `${baseUrl}/business/${slug}/apps/manage_pbu/status_export`,
                            },
                        },
                        api: {
                            root: (slug: string) => `${baseUrl}/business/${slug}/apps/api`,
                            delete: (slug: string, key: string) => `${baseUrl}/business/${slug}/apps/api/${key}`,
                        },
                        financial: {
                            setup_intent: (slug: string) => `${baseUrl}/business/${slug}/financial/setup_intent`,
                            update_payment_method: (slug: string) =>
                                `${baseUrl}/business/${slug}/financial/update_payment_method`,
                            get_earnings_history: (slug: string) => `${baseUrl}/business/${slug}/financial/get_earnings_history`,
                            get_referral_history: (slug: string) => `${baseUrl}/business/${slug}/financial/get_referral_history`,
                            get_affiliate_summary: (slug: string) =>
                                `${baseUrl}/business/${slug}/financial/get_affiliate_summary`,
                            invoices: (slug: string) => `${baseUrl}/business/${slug}/financial/invoices/`,
                            add_licenses: (slug: string) => `${baseUrl}/business/${slug}/financial/add_licenses`,
                            pay_balance: (slug: string) => `${baseUrl}/business/${slug}/financial/pay`,
                            licenses: {
                                provision: (slug: string) => `${baseUrl}/business/${slug}/financial/licenses`,
                            },
                            proposals: {
                                list: (slug: string) => `${baseUrl}/business/${slug}/financial/proposals/list/`,
                                pay: (slug: string, proposal_id: string) =>
                                    `${baseUrl}/business/${slug}/financial/proposals/pay/${proposal_id}`,
                                resend: (slug: string, proposal_id: string) =>
                                    `${baseUrl}/business/${slug}/financial/proposals/resend/${proposal_id}`,
                                create: (slug: string) => `${baseUrl}/business/${slug}/financial/proposals`,
                                update: (slug: string, proposal_id: string) =>
                                    `${baseUrl}/business/${slug}/financial/proposals/${proposal_id}`,
                                get: (slug: string, proposal_id: string) =>
                                    `${baseUrl}/business/${slug}/financial/proposals/${proposal_id}`,
                                create_partner_invoiced: (slug: string) =>
                                    `${baseUrl}/business/${slug}/financial/proposals/create_partner_invoice`,
                            },
                        },
                        employees: {
                            root: (slug: string) => `${baseUrl}/business/${slug}/employees`,
                            create: (slug: string) => `${baseUrl}/business/${slug}/employees/create`,
                            list: (slug: string) => `${baseUrl}/business/${slug}/employees/list`,
                            invites: (slug: string) => `${baseUrl}/business/${slug}/employees/invites`,
                            upload_employees: (slug: string) => `${baseUrl}/business/${slug}/employees/upload`,
                            get_employee_risk_data: (slug: string) =>
                                `${baseUrl}/business/${slug}/employees/get_employee_risk_data`,
                            get_employees_over_risk_score: (slug: string) =>
                                `${baseUrl}/business/${slug}/employees/get_employees_over_risk_score`,
                            get_most_at_risk_employees: (slug: string) =>
                                `${baseUrl}/business/${slug}/employees/get_most_at_risk_employees`,
                            get_risk_by_employees: (slug: string) =>
                                `${baseUrl}/business/${slug}/employees/get_risk_by_employees`,
                            get_employee_recent_activity: (slug: string) =>
                                `${baseUrl}/business/${slug}/employees/get_employee_recent_activity`,
                            get_employees_with_high_risk_score: (slug: string) =>
                                `${baseUrl}/business/${slug}/employees/get_employees_with_high_risk_score`,
                            get_employees_risk_over_time: (slug: string) =>
                                `${baseUrl}/business/${slug}/employees/get_employees_risk_over_time`,
                            get_employee_risk_by_department: (slug: string) =>
                                `${baseUrl}/business/${slug}/employees/get_employee_risk_by_department`,
                            get_employees_data: (slug: string) => `${baseUrl}/business/${slug}/employees/get_employees_data`,
                            invite_new_employees: (slug: string) => `${baseUrl}/business/${slug}/employees/invite_new_employees`,
                            get_employee_monitoring_coverage_rate: (slug: string) =>
                                `${baseUrl}/business/${slug}/employees/get_employee_monitoring_coverage_rate`,
                        },
                        employee: {
                            get: (slug: string, employee: string) => `${baseUrl}/business/${slug}/employees/${employee}`,
                            family: (slug: string, employee: string) =>
                                `${baseUrl}/business/${slug}/employees/${employee}/family`,
                            monthly_status_me: (slug: string, employee: string) =>
                                `${baseUrl}/business/${slug}/employees/${employee}/email/monthly-status/me`,
                            monthly_status_employee: (slug: string, employee: string) =>
                                `${baseUrl}/business/${slug}/employees/${employee}/email/monthly-status/employee`,
                            toggle_invite_reminder: (slug: string, employee: string) =>
                                `${baseUrl}/business/${slug}/employees/${employee}/toggle_invite_reminder`,
                            invite: (slug: string, employee: string) =>
                                `${baseUrl}/business/${slug}/employees/${employee}/invite`,
                            create: (slug: string, employee: string) => `${baseUrl}/business/${slug}/employees/create`,
                            update_license: (slug: string, employee: string) =>
                                `${baseUrl}/business/${slug}/employees/${employee}/change_license`,
                            update_department: (slug: string, employee: string) =>
                                `${baseUrl}/business/${slug}/employees/${employee}/change_department`,
                            update_roles: (slug: string, employee: string) =>
                                `${baseUrl}/business/${slug}/employees/${employee}/update_roles`,
                            update_access: (slug: string, employee: string) =>
                                `${baseUrl}/business/${slug}/employees/${employee}/change_access`,
                            addresses: (slug: string, employee: string, address: string) =>
                                `${baseUrl}/business/${slug}/employees/${employee}/addresses/${address}`,
                            phone_numbers: (slug: string, employee: string, phone_number: string) =>
                                `${baseUrl}/business/${slug}/employees/${employee}/phone_numbers/${phone_number}`,
                            emails: (slug: string, employee: string, email: string) =>
                                `${baseUrl}/business/${slug}/employees/${employee}/emails/${email}`,
                        },
                        licenses: {
                            get_license_information: (slug: string) =>
                                `${baseUrl}/business/${slug}/licenses/get_license_information`,
                        },
                        exposures: {
                            get_total_exposures: (slug: string) => `${baseUrl}/business/${slug}/exposures/get_total_exposures`,
                            get_exposures_chart: (slug: string) => `${baseUrl}/business/${slug}/exposures/get_exposures_chart`,
                            get_remediation_analysis: (slug: string) =>
                                `${baseUrl}/business/${slug}/exposures/get_remediation_analysis`,
                            get_exposures_employees: (slug: string) =>
                                `${baseUrl}/business/${slug}/exposures/get_exposures_employees`,
                        },
                        vendors: {
                            root: (slug: string) => `${baseUrl}/business/${slug}/vendors/`,
                            search: (slug: string) => `${baseUrl}/business/${slug}/vendors/search`,
                            get_vendor_risk_data: (slug: string) => `${baseUrl}/business/${slug}/vendors/get_vendor_risk_data`,
                            get_vendors_over_risk_score: (slug: string) =>
                                `${baseUrl}/business/${slug}/vendors/get_vendors_over_risk_score`,
                            get_most_at_risk_vendors: (slug: string) =>
                                `${baseUrl}/business/${slug}/vendors/get_most_at_risk_vendors`,
                            get_risk_by_vendors: (slug: string) => `${baseUrl}/business/${slug}/vendors/get_risk_by_vendors`,
                            get_vendors_above_min_risk_score: (slug: string) =>
                                `${baseUrl}/business/${slug}/vendors/get_vendors_above_min_risk_score`,
                            get_vendors_risk_by_department: (slug: string) =>
                                `${baseUrl}/business/${slug}/vendors/get_vendors_risk_by_department`,
                            get_vendors_risk_over_time: (slug: string) =>
                                `${baseUrl}/business/${slug}/vendors/get_vendors_risk_over_time`,
                            update_vendor_notification: (slug: string) =>
                                `${baseUrl}/business/${slug}/vendors/update_vendor_notification`,
                            get_vendor_notification: (slug: string) =>
                                `${baseUrl}/business/${slug}/vendors/get_vendor_notification`,
                            get_vendor_notification_sent: (slug: string) =>
                                `${baseUrl}/business/${slug}/vendors/get_vendor_notification_sent`,
                            get_vendor_list: (slug: string) => `${baseUrl}/business/${slug}/vendors/get_vendor_list`,
                        },
                        vendor: {
                            root: (slug: string, vendor: string) => `${baseUrl}/business/${slug}/vendor/${vendor}`,
                            request_pra_access: (slug: string, vendor: string) =>
                                `${baseUrl}/business/${slug}/vendor/${vendor}/request_pra_access`,
                            grant_pra_access: (slug: string, vendor: string) =>
                                `${baseUrl}/business/${slug}/vendor/${vendor}/grant_pra_access`,
                            get_vendor_risk_by_employees: (slug: string, vendor: string) =>
                                `${baseUrl}/business/${slug}/vendor/${vendor}/get_risk_by_employees`,
                            get_member_sentiment: (slug: string, vendor: string) =>
                                `${baseUrl}/business/${slug}/vendor/${vendor}/get_member_sentiment`,
                            contacts: {
                                list: (slug: string, vendor: string) => `${baseUrl}/business/${slug}/vendor/${vendor}/contacts/`,
                                create: (slug: string, vendor: string) =>
                                    `${baseUrl}/business/${slug}/vendor/${vendor}/contacts/`,
                                update: (slug: string, vendor: string, contactId: string) =>
                                    `${baseUrl}/business/${slug}/vendor/${vendor}/contacts/${contactId}`,
                                delete: (slug: string, vendor: string, contactId: string) =>
                                    `${baseUrl}/business/${slug}/vendor/${vendor}/contacts/${contactId}`,
                            },
                        },
                        dashboard: {
                            get_recommended_steps: (slug: string) =>
                                `${baseUrl}/business/${slug}/dashboard/get_recommended_steps`,
                            get_activity_highlights: (slug: string) =>
                                `${baseUrl}/business/${slug}/dashboard/get_activity_highlights`,
                        },
                    },
                },
            },
        },
    };
});
