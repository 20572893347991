export default {
    root: ({ props, context, parent }) => ({
        class: ['multiselect-root'],
        style: {
            // display: 'flex',
            // 'justify-content': 'space-between',
            // 'align-items': 'center',
        },
    }),
    header: ({ props, context, parent }) => ({
        style: {
            display: 'none',
        },
    }),

    overlay: ({ props, context, parent }) => ({
        class: ['multiselect-overlay'],
    }),

    list: ({ props, context, parent }) => ({
        class: ['multiselect-list'],
    }),
};
