export default {
    root: ({ instance }) => {
        const variant = instance.$attrs.variant;

        return variant === 'secondary' ? 'secondary-tablist' : 'primary-tablist';
    },
    content: ({ instance }) => [
        'w-full',
        {
            'overflow-x-auto': instance.$pcTabs.scrollable,
        },
    ],
    tabs: 'flex',
    inkbar: ({ instance }) => (instance.$attrs.variant === 'secondary' ? 'secondary-tablist-inkbar' : 'primary-tablist-inkbar'),
};
