<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

<script setup>
const route = useRoute();

useHead({
    title: route.name + ' | Privacy Bee',
});

//if route.name changes then update the title
watch(
    () => route.name,
    (name) => {
        useHead({
            title: name + ' | Privacy Bee',
        });
    }
);
</script>
