export default {
    content: {
        class: [''],
    },
    listContainer: {
        class: ['select-list-container'],
    },
    option: {
        class: ['select-option'],
    },
    label: {
        class: ['select-label'],
    },

    root: ({ props, context, parent }) => ({
        class: [
            // Font
            'select-root',

            {
                'pselect-disabled': props.disabled,
            },
        ],
    }),
};
