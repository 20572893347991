
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as callbackNluM3LZzKGMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/auth/callback.vue?macro=true";
import { default as sign_45inTO1jpd5EAxMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/auth/sign-in.vue?macro=true";
import { default as indexKX1Fy9jsK7Meta } from "/home/runner/work/hive-ui/hive-ui/pages/main/business/[slug]/index.vue?macro=true";
import { default as compskOOPNwDIyPMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/comps.vue?macro=true";
import { default as indexSSjIyB5kNfMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/index.vue?macro=true";
import { default as modalsDyhgRNwi0xMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/modals.vue?macro=true";
import { default as companiesyPJHD7qtx7Meta } from "/home/runner/work/hive-ui/hive-ui/pages/main/personal/companies.vue?macro=true";
import { default as contactskpOHKVNzPzMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/personal/contacts.vue?macro=true";
import { default as dashboardvymvf8DBDJMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/personal/dashboard.vue?macro=true";
import { default as detailsBVzrTQw4QKMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/personal/details.vue?macro=true";
import { default as searchaXjy5T1AfvMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/personal/search.vue?macro=true";
import { default as settingseV1g7FgHOMMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/personal/settings.vue?macro=true";
import { default as universityus1rtbQnjhMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/personal/university.vue?macro=true";
import { default as vaultmSnkRP4ozSMeta } from "/home/runner/work/hive-ui/hive-ui/pages/main/personal/vault.vue?macro=true";
import { default as _91slug_93n2ApAA9AHyMeta } from "/home/runner/work/hive-ui/hive-ui/pages/trust/[slug].vue?macro=true";
import { default as _91contact_id_93U4BnBuoqqpMeta } from "/home/runner/work/hive-ui/hive-ui/pages/trust/contact/[contact_id].vue?macro=true";
import { default as indexlqV3kJi4VGMeta } from "/home/runner/work/hive-ui/hive-ui/pages/trust/index.vue?macro=true";
export default [
  {
    name: "auth-callback",
    path: "/main/auth/callback",
    meta: callbackNluM3LZzKGMeta || {},
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/auth/callback.vue")
  },
  {
    name: "Sign In",
    path: "/main/auth/sign-in",
    meta: sign_45inTO1jpd5EAxMeta || {},
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/auth/sign-in.vue")
  },
  {
    name: "main-business-slug",
    path: "/main/business/:slug()",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/business/[slug]/index.vue")
  },
  {
    name: "main-comps",
    path: "/main/comps",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/comps.vue")
  },
  {
    name: "main",
    path: "/main",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/index.vue")
  },
  {
    name: "main-modals",
    path: "/main/modals",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/modals.vue")
  },
  {
    name: "main-personal-companies",
    path: "/main/personal/companies",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/personal/companies.vue")
  },
  {
    name: "main-personal-contacts",
    path: "/main/personal/contacts",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/personal/contacts.vue")
  },
  {
    name: "main-personal-dashboard",
    path: "/main/personal/dashboard",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/personal/dashboard.vue")
  },
  {
    name: "main-personal-details",
    path: "/main/personal/details",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/personal/details.vue")
  },
  {
    name: "Private Search",
    path: "/main/personal/search",
    meta: searchaXjy5T1AfvMeta || {},
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/personal/search.vue")
  },
  {
    name: "main-personal-settings",
    path: "/main/personal/settings",
    meta: settingseV1g7FgHOMMeta || {},
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/personal/settings.vue")
  },
  {
    name: "main-personal-university",
    path: "/main/personal/university",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/personal/university.vue")
  },
  {
    name: "main-personal-vault",
    path: "/main/personal/vault",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/main/personal/vault.vue")
  },
  {
    name: "trust-slug",
    path: "/trust/:slug()",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/trust/[slug].vue")
  },
  {
    name: "trust-contact-contact_id",
    path: "/trust/contact/:contact_id()",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/trust/contact/[contact_id].vue")
  },
  {
    name: "trust",
    path: "/trust",
    component: () => import("/home/runner/work/hive-ui/hive-ui/pages/trust/index.vue")
  }
]