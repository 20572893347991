import type { Person } from '~/types/personal/person';
import * as Sentry from '@sentry/vue';
import { ref, watch } from 'vue';
import { defineStore } from 'pinia';

export const usePersonalStore = defineStore('personal', () => {
    const person = ref(null as Person | null);
    const auth = useAuthStore();
    const nuxtApp = useNuxtApp();

    // Fetch and set the original person data
    async function getPerson(forceUpdate: boolean = false) {
        if (!auth.user) return;
        if (person.value && !forceUpdate) return;

        const { data, error } = await usePbFetch(nuxtApp.$api.v3.people.person(auth.user.id));

        if (error.value) {
            Sentry.captureException({ personal_getperson_error: error.value });
        }

        person.value = data.value.data;
    }

    /**
     * account management logic (personal information)
     */

    const unsavedChanges = ref([]); // this is used for first_name, last_name, middle_name, dob

    const new_first_name = ref('');
    const new_last_name = ref('');
    const new_middle_name = ref('');
    const new_dob = ref();

    watch(person, (newVal, oldVal) => {
        if (!newVal) return;
        new_first_name.value = newVal.first_name;
        new_last_name.value = newVal.last_name;
        new_middle_name.value = newVal.middle_name || '';
        new_dob.value = newVal.dob;
    });

    watch(new_first_name, (newVal, oldVal) => {
        if (newVal !== person.value?.first_name) {
            if (unsavedChanges.value.includes('first_name')) return;
            unsavedChanges.value.push('first_name');
        } else {
            unsavedChanges.value = unsavedChanges.value.filter((item) => item !== 'first_name');
        }
    });

    watch(new_last_name, (newVal, oldVal) => {
        if (newVal !== person.value?.last_name) {
            if (unsavedChanges.value.includes('last_name')) return;
            unsavedChanges.value.push('last_name');
        } else {
            unsavedChanges.value = unsavedChanges.value.filter((item) => item !== 'last_name');
        }
    });

    watch(new_middle_name, (newVal, oldVal) => {
        if (newVal === '' && !person.value?.middle_name) {
            unsavedChanges.value = unsavedChanges.value.filter((item) => item !== 'middle_name');
        } else if (newVal !== person.value?.middle_name) {
            if (unsavedChanges.value.includes('middle_name')) return;
            unsavedChanges.value.push('middle_name');
        } else {
            unsavedChanges.value = unsavedChanges.value.filter((item) => item !== 'middle_name');
        }
    });

    watch(new_dob, (newVal, oldVal) => {
        if (newVal !== person.value?.dob) {
            if (unsavedChanges.value.includes('dob')) return;
            unsavedChanges.value.push('dob');
        } else {
            unsavedChanges.value = unsavedChanges.value.filter((item) => item !== 'dob');
        }
    });

    async function saveChanges() {
        const data = {
            first_name: new_first_name.value,
            last_name: new_last_name.value,
            middle_name: new_middle_name.value,
            //replace anything bhind T: example 2024-12-19T23:00:00.000Z becomes 2024-12-19
            dob: new_dob.value.getMonth() + 1 + '-' + new_dob.value.getDate() + '-' + new_dob.value.getFullYear(),
        };

        const { error } = await usePbFetch(nuxtApp.$api.v3.me.updateMe(), {
            method: 'PATCH',
            body: JSON.stringify(data),
        });

        if (error.value) {
            Sentry.captureException({ personal_savechanges_error: error.value });
        }

        unsavedChanges.value = [];
        getPerson();
    }

    async function updatePerson() {
        const postData = person.value;

        const { error } = await usePbFetch(nuxtApp.$api.v3.me.updateMe(), {
            method: 'PATCH',
            body: JSON.stringify(postData),
        });

        if (error.value) {
            console.error({ personal_savechanges_error: error.value });
        }

        await getPerson(true);
    }

    return {
        person,
        getPerson,
        unsavedChanges,
        new_first_name,
        new_last_name,
        new_middle_name,
        new_dob,
        saveChanges,
        updatePerson,
    };
});
