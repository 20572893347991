export default {
    root: ({ props, context, parent, instance }) => ({
        class: [
            {
                'p-btn-root_disabled': context.disabled,
                'p-btn-root_primary': instance.$attrs.variant == 'primary' || !instance.$attrs.variant,
                'p-btn-root_error': instance.$attrs.variant == 'error',
                'p-btn-root_success': instance.$attrs.variant == 'success',
                'p-btn-root_contrast': instance.$attrs.variant == 'contrast',
                'p-btn-root_secondary': instance.$attrs.variant == 'secondary',
                'p-btn-root_tertiary': instance.$attrs.variant == 'tertiary',
                'p-btn-root_link': instance.$attrs.variant == 'link',
            },
        ],
        style: {
            //svg fill
            '--svg-fill': instance.$attrs['icon-color'] || 'white',
        },
    }),
    label: ({ props }) => ({}),
    icon: ({ props, context, parent, instance }) => ({
        class: ['icon-bg'],
        style: {
            'background-image': `url('${props.icon}')`,
        },
    }),
    loadingIcon: ({ props }) => ({
        class: ['loading-spinner'],
    }),
    badge: ({ props }) => ({
        class: [{ 'ml-2 w-4 h-4 leading-none flex items-center justify-center': props.badge }],
    }),
};
