export default {
    mask: (options) => ({
        // class: [
        //     'modal fade opacity-0',
        //     {
        //         'show opacity-100': options.state.containerVisible,
        //     },
        // ],
        style: {
            background: 'rgba(0, 0, 0, .5)',
        },
    }),
    root: 'p-confirm-dialog-root',
    title: 'confirm-dialog-title',
};
